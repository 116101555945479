import { addDecimalSeparator, calculateNoofDays, getCurrencySymbol } from "./statHelpers";

function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function getOrgId(){
    let campaignId = getParameterByName("clickitsid");
	campaignId = window.atob(campaignId);
	campaignId = campaignId.split("-");
    return campaignId[0];
}

export function getClickitsId(){
    let campaignId = getParameterByName("clickitsid");
	campaignId = window.atob(campaignId);
	campaignId = campaignId.split("-");
    return campaignId[1];
}

export function dateFormat(date, format)
{
    if(date && format)
    {
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let smonths= ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        var d = new Date(date);
        let str = format.replaceAll("{mm}",months[d.getMonth()]);
        let str1 =str.replaceAll("{m}", smonths[d.getMonth()]);
        let str2 = str1.replaceAll("{dd}", (d.getDate() <= 9) ? "0"+d.getDate() : d.getDate());
        let str3 = str2.replaceAll("{d}", d.getDate());
        let str4 = str3.replaceAll("{YY}", d.getFullYear().toString().slice(-2));
        let str5 = str4.replaceAll("{YYYY}", d.getFullYear());
        return str5;
    }
}

export function nameFormat(fname, lname, format)
{
    let str = format.replaceAll("{FN}",fname);
    let str1 = str.replaceAll("{LN}", lname);
    let str2 = str1.replaceAll("{FI}", fname.substr(0,1));
    let str3 = str2.replaceAll("{LI}", lname.substr(0,1))
    return str3;
}
export function FormatStream(streamText, member, themeInfo)
{
    let dtFormat = themeInfo.GlobalSettings.DateFormat;
    let symbol = getCurrencySymbol(themeInfo)
    let nametxt = nameFormat(member.FirstName, member.LastName, streamText);
    let dtTxt = nametxt.replaceAll("{Date}", dateFormat(member.Date, dtFormat));
    let amtTxt = dtTxt.replaceAll("{Amount}", symbol+member.Amount);
    return amtTxt;
}

export function formatGoalInfo(themeInfo,goalAmt,expectedRevenue){
    let symbol = getCurrencySymbol(themeInfo)
    let separator = themeInfo.GlobalSettings && themeInfo.GlobalSettings.NumberFormat && themeInfo.GlobalSettings.NumberFormat.DecimalSeparator;
    let decimalsToShow = themeInfo.GlobalSettings && themeInfo.GlobalSettings.NumberFormat && themeInfo.GlobalSettings.NumberFormat.NumOfDecimalsToShow;
    let campaignInfo = themeInfo.CampaignTextSection;
    
    let campaignEndDt = themeInfo && themeInfo.GlobalSettings && themeInfo.GlobalSettings.CampaignEndDate;
    
    let campaignEndDate;
    if(campaignEndDt){
        campaignEndDate = campaignEndDt;
    }else{
        /*let dt = new Date();
        campaignEndDate = dt.getFullYear()+"-"+(dt.getMonth()+1)+"-"+dt.getDate();*/
        campaignEndDate = "";
    }
    
    let dtFormat = themeInfo && themeInfo.GlobalSettings && themeInfo.GlobalSettings.DateFormat;

    if(campaignInfo)
    {
        let noOfDays = (calculateNoofDays(campaignEndDate) > -1) ? calculateNoofDays(campaignEndDate) : "";
        let endsOn = (calculateNoofDays(campaignEndDate) > -1) ? dateFormat(campaignEndDate, dtFormat) : dateFormat(campaignEndDate, dtFormat);
        let txt = campaignInfo.replaceAll("{ExpectedRevenue}", symbol + addDecimalSeparator(separator, goalAmt, decimalsToShow));
        let txt1 = txt.replaceAll("{ValueWonOpportunitiesinCampaign}", symbol + addDecimalSeparator(separator, expectedRevenue, decimalsToShow));
        if(campaignEndDate)
        {
            let txt2 = txt1.replaceAll("{Days}", noOfDays);
            let txt3 = txt2.replaceAll("{EndDate}", endsOn);
            return txt3;
        }else{
            let txt2 = txt1.replaceAll("{Days}", '');
            let txt3 = txt2.replaceAll("{EndDate}", '');
            return txt3;
        }
     
    }
}

export function formatCurrency(n, c, d, t) {
    "use strict";

    var s, i, j;

    c = isNaN(c = Math.abs(c)) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t;

    s = n < 0 ? "-" : "";
    i = parseInt(n = Math.abs(+n || 0).toFixed(c), 10) + "";
    j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}


export function amountFormat(themeInfo, amount, format)
{
    let symbol = getCurrencySymbol(themeInfo)
    let str = format.replace("{Amount}",symbol+""+amount);
    return str;
}