export function getBackgroundImage(themeInfo, widgetInfo){

    let image = false;
    if(themeInfo.Images.BannerImageType === "BasedOnDate")
    {
        let allDates = themeInfo.Images.BasedOnDate;
        
        allDates && allDates.sort(function(a,b){
            return new Date(a.From) - new Date(b.From)
        })

       
        if(allDates && allDates.length > 0)
        {
            for(let k = 0; k < allDates.length; k++)
            {
                if(validateDates(allDates[k].From) || k === allDates.length - 1 )
                {
                    return allDates[k].BannerPath
                }
               
            }
        }
    }
    else{

        let allTargets = themeInfo.Images.BasedOnTarget
        allTargets && allTargets.sort(function(a, b){
            return a.From - b.From;
        });
        
        if(allTargets && allTargets.length > 0)
        {
            for(let i = 0; i < allTargets.length; i++)
            {
                let start = parseInt(allTargets[i].From);
                let next = (allTargets.length <= i+1) ? parseInt(allTargets[i].From) : parseInt(allTargets[i+1].From)

                if(validateTarget(start,next, widgetInfo) || i === allTargets.length - 1)
                {
                    return allTargets[i].BannerPath;
                }
            }
        }
    }
    return image;
}

export function getBackgroundColor(themeInfo)
{
    return themeInfo && themeInfo.GlobalSettings && themeInfo.GlobalSettings.BackgroundColor;
}

function validateTarget(start,next, widgetInfo)
{
    return widgetInfo.AmountWonOpportunities >= start && widgetInfo.AmountWonOpportunities < next;
}

function validateDates(fromD)
{
    //let dateCheck = new Date().toLocaleDateString();
    let d = new Date();
    let dateCheck = (d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear();
    //9/13/2023
    let c = dateCheck.split("/");
    let d1 = fromD.split("-");
    let from = new Date(d1[0], parseInt(d1[1])-1, d1[2]);
    let check = new Date(c[2], parseInt(c[0])-1, c[1]);
    return check <= from;
}