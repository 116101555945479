export function getBackgroundColor (themeInfo)
{
    return themeInfo && themeInfo.PaymentSection && themeInfo.PaymentSection.BackgroundColor;
}

export function calculatePercentage(awo, er){
    let percentage = 0;
    if(awo){
        percentage = (er / awo) * 100 ;
        return percentage.toFixed(2);
    }
    else{
        return 0;
    } 
}
export function getCurrencySymbol(themeInfo){
    //let platForm = (getParameterByName("p")==="1") ? "Salesforce" : "Connect";
    return themeInfo.GlobalSettings.CurrencySymbol;
}
export function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

/*function replaceLast(x, y, z){
    var a = x.split("");
    a[x.lastIndexOf(y)] = z;
    return a.join("");
}*/

export function addDecimalSeparator(separtor=",", amtNum = 0, numOfDecimals = 3)
{
    amtNum = amtNum === null ? 0 : amtNum;
    amtNum = amtNum.toFixed(numOfDecimals);
    amtNum = amtNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separtor);
    if(separtor == "."){
        var n = amtNum.lastIndexOf(".");
        amtNum = amtNum.slice(0, n) + amtNum.slice(n).replace(".", ",");
    }
    //console.log(amtNum)
    return amtNum;
}




export function getDecimalSeparator(themeInfo){
    return themeInfo && themeInfo.GlobalSettings && themeInfo.GlobalSettings.NumberFormat && themeInfo.GlobalSettings.NumberFormat.DecimalSeparator;
}
export function getNumOfDecimals(themeInfo){
    return themeInfo && themeInfo.GlobalSettings && themeInfo.GlobalSettings.NumberFormat && themeInfo.GlobalSettings.NumberFormat.NumOfDecimalsToShow;
}
export function calculateNoofDays(endDate)
{
    const date1 = new Date();
    const date2 = new Date(endDate);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.ceil(diffInTime / oneDay);
    return (diffInDays >= 0 ) ? diffInDays : -1;
}

export function parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0]-1, mdy[1]); //Y M d
}

export function getButtonStyles(themeInfo,section,button)
{
    let btnInfo = themeInfo[section][button];
    let fontFamily = btnInfo.FontFamily ? btnInfo.FontFamily :'';
    let fontSize = btnInfo.FontSize ? btnInfo.FontSize+"px !important" :'';
    let backgroundColor = btnInfo.BackgroundColor ? btnInfo.BackgroundColor :'';
    let color = btnInfo.TextColor ? btnInfo.TextColor :'';
    let formatingStyles = btnInfo.TextStyle ? btnInfo.TextStyle :'';
    return "background-color:"+backgroundColor+";font-size:"+fontSize+";font-family:"+fontFamily+";color:"+color+";"+getFormatingStyles(formatingStyles);
}
export function getButtonHoverStyles(themeInfo,section,button)
{
    let btnInfo = themeInfo[section][button];
    let backgroundColor = btnInfo.SelectedBackgroundColor ? btnInfo.SelectedBackgroundColor :'';
    let textColor = btnInfo.SelectedTextColor ? btnInfo.SelectedTextColor :'';
    let fontSize = btnInfo.FontSize ? btnInfo.FontSize+"px !important" :'';
    let formatingStyles = btnInfo.TextStyle ? btnInfo.TextStyle :'';
    let fontFamily = btnInfo.FontFamily ? btnInfo.FontFamily :'';
    return "background-color:"+backgroundColor+" !important; font-family:"+fontFamily+";color:"+textColor+";font-size:"+fontSize+";"+getFormatingStyles(formatingStyles);
}
function getFormatingStyles(styles){
    let txt='';
    if(styles){
        styles = styles.split(";");
        txt += styles.includes("underline") ? "text-decoration:underline;" : '';
        txt += styles.includes("bold") ? "font-weight:bold;" : '';
        txt += styles.includes("italic") ? "font-style:italic;" : '';
        txt += styles.includes("center") ? "text-align:center;" : '';
        txt += styles.includes("left") ? "text-align:left;" : '';
        txt += styles.includes("right") ? "text-align:right;" : '';
        return txt;
    }
}