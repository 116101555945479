import React from 'react';
import ReactDOM from 'react-dom';
import Widget from './Widget';

ReactDOM.render(
  <React.StrictMode>
    <Widget />
  </React.StrictMode>,
  document.getElementById('root')
);
